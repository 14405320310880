<template>
  <div class="pt-3">
    <Loader v-if="performingRequest || ((!paidAssignments || paidAssignments.length == 0) && endDate && startDate)" />
    <div class="dashboard__container--header">
      <div class="whiteBack flex flex-row mb-5">
        <div class="mb-3">
          <label for="start">Start Date</label>
          <input type="date" v-model="startDate" @change="checkDates()" id="start" />
        </div>
        <div class="mb-3 ml-5">
          <label for="end">End Date</label>
          <input type="date" v-model="endDate" @change="checkDates()" id="end" />
        </div>
        <div class="mb-3 ml-5" v-if="startDate && endDate">
          <button class="btn btn__small btn__dark mt-5" @click="clear()">Clear</button>
        </div>
      </div>
      <button class="btn btn__small btn__outlined ml-3" v-if="paidAssignments && paidAssignments.length >= 1 && endDate && startDate" @click="exportReport()">export group</button>

    </div>
    <div class="dashboard__container--body" v-if="paidAssignments && paidAssignments.length >= 1 && endDate && startDate">

      <PayrollTable  :eventAssignments="paidAssignments" />

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
import router from '@/router'
// import firebase from 'firebase/app';
import ExportService from "@/services/ExportService"
import PayrollTable from '@/components/PayrollTable.vue'
// const fb = require('../../firebaseConfig.js')

export default {
  name: 'paidAssignments',
  data: () => ({
    performingRequest: false,
    startDate: null,
    endDate: null,
  }),
  components: {
    Loader,
    PayrollTable
  },
  computed: {
    ...mapState(['userProfile', 'paidAssignments']),
    assignmentsByDate() {
      if (this.startDate && this.endDate) {
        let startDate = new Date(this.startDate);
        let endDate = new Date(this.endDate);
      
        return this.paidAssignments.filter(item => {
          let eventDate = new Date(item.date)
          return ((eventDate >= startDate) && (eventDate <= endDate)) 
        })
      } else {
        return []
      }
    },
  },
  methods: {
    clear() {
      this.$store.dispatch("clearPaidAssignments")
      this.startDate = null
      this.endDate = null
    },
    checkDates() {
      if ((this.startDate && this.startDate != null) && (this.endDate && this.endDate != null)) {
        console.log('getting')
        this.performingRequest = true
        this.$store.dispatch("getPaidAssignments", {
          start: this.startDate,
          end: this.endDate
        })
      } else {
      }
      this.performingRequest = false
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;

        return moment.utc(postedDate).local().format('HH:mm:ss A')
        // return moment(postedDate).format('HH:MM A')
      } else {
        return null
      }
    },
    exportReport() {
      this.performingRequest = true
      const exportHeaders = [
        "Work Date",
        "Paid Date",
        "City",
        "State",
        "Zip",
        "First Name",
        "Last Name",
        "Position",
        "Hourly Rate",
        "Clock-In",
        "Clock-Out",
        "Total Hours",
        "Break",
        "Reg Hours",
        "Billable Hours",
        "Overtime",
        "2x Overtime",
        "Break Penalty",
        "CC Tips",
        "Total Paid"
      ];
      const exportItems = [];
      for (var key in this.paidAssignments) {
        if (!this.paidAssignments[key].hidden) {


          let billHours
          if (this.paidAssignments[key].minHours > this.paidAssignments[key].regHours) {
            billHours = this.paidAssignments[key].minHours
          } else {
            billHours = this.paidAssignments[key].regHours
          } 
          let paidDate
          if (this.paidAssignments[key].returned && this.paidAssignments[key].returned.effectiveDate) {
            paidDate = this.paidAssignments[key].returned.effectiveDate
          } else {
            paidDate = null
          }

          // let inTime = (this.formatDate(this.paidAssignments[key].checkInTimeStamp) || this.paidAssignments[key].inTime)
          // let outTime = (this.formatDate(this.paidAssignments[key].checkOutTimeStamp) || this.paidAssignments[key].outTime)

          exportItems.push([
            this.paidAssignments[key].date,
            paidDate,
            this.paidAssignments[key].eventInfo.event_city,
            this.paidAssignments[key].eventInfo.event_state,
            this.paidAssignments[key].eventInfo.event_zip,
            this.paidAssignments[key].firstName,
            this.paidAssignments[key].lastName,
            this.paidAssignments[key].position,
            this.paidAssignments[key].regRate,
            this.paidAssignments[key].inTime,
            this.paidAssignments[key].outTime,
            this.paidAssignments[key].totalHours,
            this.paidAssignments[key].breakTime,
            this.paidAssignments[key].regHours,
            billHours,
            this.paidAssignments[key].otHours,
            this.paidAssignments[key].ot2Hours,
            this.paidAssignments[key].mbp,
            this.paidAssignments[key].tips,
            this.paidAssignments[key].payTotal
          ]);
        }
      }
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    },
  
  },
  beforeDestroy () {
    this.performingRequest = null
    delete this.performingRequest
    this.startDate = null
    delete this.startDate
    this.endDate = null
    delete this.endDate
    // this.$store.dispatch("clearTotalAssignments")
    this.$store.dispatch("clearPaidAssignments")
    console.log(this)
  }
}
	
</script>